/*------------------------------------*\
    #PAGE
\*------------------------------------*/

body {
    color: $color-brand;
    @include font-size(16px, 20px);
    font-family: Arial, Helvetica, sans-serif;
}

