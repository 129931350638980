///* ========================================================================
//   #CONFIG
//   ======================================================================== */

$default-gutter: 20px;


$mq-breakpoints: (
    'phone-small':    320px,
    'phone':          480px,
    'tablet':         769px,
    'desktop':        992px,
    'desktop-large':  1280px
);