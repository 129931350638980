/* ==========================================================================
   #GRID
   ========================================================================== */


@lost flexbox flex;
@lost gutter 0px;
@lost cycle none;

// Default


.grid {
    lost-flex-container: row;

}

.grid__item {
    margin-bottom: $default-gutter;

}




/*
.one-third {
    @include mq($from: tablet, $until: desktop) {
        lost-column: 1/2 2 $default-gutter;
    }

    @include mq($from: desktop) {
        lost-column: 1/3 3 $default-gutter;
    }
}

*/


.one-half {
    lost-column: 1/1;
    display: flex;
    @include mq($from: tablet) {
        lost-column: 1/2 null 20px;
    }
}


.one-quarter {
    lost-column: 1/2 2 20px;
    display: flex;

    @include mq($from: tablet) {
        lost-column: 1/4 4 20px;
    }
}



.one-quarter-special {
    lost-column: 1/1;
    display: flex;

    @include mq($from: phone) {
        lost-column: 1/2 2 20px;
    }

    @include mq($from: tablet) {
        lost-column: 1/4 4 20px;
    }
}



.one-third {
    lost-column: 1/1;
    display: flex;
    @include mq($from: tablet) {
        lost-column: 1/3 null 20px;
    }
}


.two-third {
    lost-column: 1/1;
    display: flex;
    @include mq($from: tablet) {
        lost-column: 2/3 2 20px;
    }
}




