///* ========================================================================
//   #COLORS
//   ======================================================================== */


$color-red:         #E20042;
$color-brand:       #577A7B;

$color-blue:        #2d4485;
$color-blue-grey:   #A1AEC3;
$color-blue-light:  #F1F3F6;
$color-grey:        #9B9B9B;
$color-grey-darken: #484848;
$color-ui:          #DCDCDC;
$color-ui-light:    #F1F1F1;
$color-headings:    #4A4A4A;

$color-grey-light:  #A0A0A0;
$color-btn-primary: #1967BB;

