/*------------------------------------*\
    #BLOCKQUOTE
\*------------------------------------*/



blockquote {
    @include font-size(16px, 26px);
    color: $color-blue;
    font-style: italic;
    margin-top: $base-spacing-unit*2;
    margin-bottom: $base-spacing-unit*2;
    margin-left: 10%;
    margin-right: 10%;
    &:before {
        content: "“";
    }
    &:after {
        content: "”";
    }
    p {
        display: inline;
    }
}


