/*------------------------------------*\
    #HORIZONTAL RULE
\*------------------------------------*/


hr {
    border: 0;
    height: 1px;
    background: $color-ui;
}
