.Zebra_DatePicker {
    position: absolute;
    background: #FFF;
    border: 1px solid #999;
    z-index: 1200;
    top: 0;
    padding: 5px
}

.Zebra_DatePicker_Icon_Wrapper {
    display: block!important;
    max-width: 500px;
    width: 100%;
}


.Zebra_DatePicker *, .Zebra_DatePicker *:after, .Zebra_DatePicker *:before {
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important
}

.Zebra_DatePicker * {
    margin: 0;
    padding: 0;
    color: #373737;
    background: transparent;
    border: none
}

.Zebra_DatePicker.dp_visible {
    visibility: visible;
    filter: alpha(opacity=100);
    -khtml-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    transition: opacity 0.2s ease-in-out
}

.Zebra_DatePicker.dp_hidden {
    visibility: hidden;
    filter: alpha(opacity=0);
    -khtml-opacity: 0;
    -moz-opacity: 0;
    opacity: 0
}

.Zebra_DatePicker table {
    border-collapse: collapse;
    border-spacing: 0;
    width: auto;
    table-layout: auto
}

.Zebra_DatePicker table th, .Zebra_DatePicker table td {
    text-align: center;
    padding: 5px 0
}

.Zebra_DatePicker table td {
    cursor: pointer
}

.Zebra_DatePicker table td.dp_disabled, .Zebra_DatePicker table td.dp_not_in_month, .Zebra_DatePicker table td.dp_not_in_month_selectable, .Zebra_DatePicker table td.dp_weekend_disabled {
    color: #DEDEDE;
    cursor: default
}

.Zebra_DatePicker table td.dp_disabled_current {
    color: rgba(58, 135, 173, 0.4) !important
}

.Zebra_DatePicker table td.dp_not_in_month_selectable {
    cursor: pointer
}

.Zebra_DatePicker table td.dp_week_number {
    font-weight: bold;
    cursor: text
}

.Zebra_DatePicker table td.dp_current {
    color: #3A87AD
}

.Zebra_DatePicker table td.dp_selected {
    background: #039;
    color: #FFF
}

.Zebra_DatePicker table td.dp_selected.dp_hover {
    color: #000
}

.Zebra_DatePicker table td.dp_hover {
    background: #DEDEDE
}

.Zebra_DatePicker .dp_daypicker td, .Zebra_DatePicker .dp_daypicker th, .Zebra_DatePicker .dp_monthpicker td, .Zebra_DatePicker .dp_monthpicker th, .Zebra_DatePicker .dp_yearpicker td, .Zebra_DatePicker .dp_yearpicker th, .Zebra_DatePicker .dp_timepicker td, .Zebra_DatePicker .dp_timepicker th {
    width: 30px
}

.Zebra_DatePicker .dp_header .dp_hover, .Zebra_DatePicker .dp_footer .dp_hover {
    background: #DEDEDE;
    color: #373737
}

.Zebra_DatePicker .dp_header {
    margin-bottom: 3px
}

.Zebra_DatePicker .dp_header .dp_previous, .Zebra_DatePicker .dp_header .dp_next {
    width: 30px
}

.Zebra_DatePicker .dp_header .dp_caption {
    font-weight: bold
}

.Zebra_DatePicker .dp_monthpicker td, .Zebra_DatePicker .dp_yearpicker td {
    width: 33.3333%
}

.Zebra_DatePicker .dp_timepicker td {
    width: 25%
}

.Zebra_DatePicker .dp_timepicker td.dp_disabled {
    color: #000
}

.Zebra_DatePicker .dp_footer {
    margin-top: 3px
}

.Zebra_DatePicker .dp_footer td.dp_today, .Zebra_DatePicker .dp_footer td.dp_clear, .Zebra_DatePicker .dp_footer td.dp_timepicker_toggler, .Zebra_DatePicker .dp_footer td.dp_confirm {
    padding: 3px
}

.Zebra_DatePicker .dp_footer td.dp_timepicker_toggler, .Zebra_DatePicker .dp_footer td.dp_confirm {
    background-repeat: no-repeat;
    background-position: center center;
    padding-left: 13px;
    padding-right: 13px;
    background-image: url(/peoplewise/img/timepicker.png)
}

.Zebra_DatePicker .dp_footer td.dp_timepicker_toggler_calendar {
    background-image: url(/peoplewise/img/datepicker.png)
}

.Zebra_DatePicker .dp_footer td.dp_confirm {
    width: 50%;
    background-image: url(/peoplewise/img/select.png)
}

button.Zebra_DatePicker_Icon {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background: url(/peoplewise/img/calendar.png) no-repeat left top;
    text-indent: -9000px;
    border: none;
    cursor: pointer;
    padding: 0;
    line-height: 0;
    margin: 0 0 0 3px
}

button.Zebra_DatePicker_Icon.Zebra_DatePicker_Icon_Disabled {
    background-image: url(/peoplewise/img/calendar-disabled.png)
}

button.Zebra_DatePicker_Icon.Zebra_DatePicker_Icon_Inside_Right {
    margin: 0 3px 0 0
}

button.Zebra_DatePicker_Icon.Zebra_DatePicker_Icon_Inside_Left {
    margin: 0 0 0 3px
}

.Zebra_DatePicker, .Zebra_DatePicker .dp_header .dp_hover, .Zebra_DatePicker .dp_footer .dp_hover, .Zebra_DatePicker td.dp_selected, .Zebra_DatePicker .dp_footer .dp_hover, .Zebra_DatePicker td.dp_hover {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}
