/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a {
    text-decoration: none;
    color: $color-brand;
    transition: $global-transition;
    @include attention(){
        color: #000;
    }
}

