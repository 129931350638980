/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

h1, .alpha {
    @include inuit-font-size(24px, 28px);
    margin-bottom: $half-spacing-unit;
    font-weight: normal;
}


h2, .beta {
    @include font-size(22px, 25px);
    margin-bottom: $half-spacing-unit;
}


h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}