/* ==========================================================================
   #QUESTIONS
   ========================================================================== */


$expandend: #B8E986;

.count {
    background-color: darken($color-brand, 5%);
    padding: 10px 20px;
    color: #fff;
    @include font-size(30px);
    width: 120px;
    display: block;
    margin-bottom: 20px;
    text-align: center;
    float: right;

    &.fixed {
        position: fixed;
        top: 0;
        right: 0;
    }
}


.image__item {
    background-color: grey;
    height: 100px;
    width: 100%;
    transition: all .2s ease-in-out;

    @include attention(){
        cursor: pointer;
        transform: scale(1.1);
    }

    &.checked {
        opacity: 0.7;
    }
}


.competences__item {
    border: solid 1px $color-brand;
    transition: all .2s ease-in-out;
    h2 {
        background-color: $color-brand;
        color: #fff;
        padding: 10px;
        @include font-size(16px);
        text-transform: uppercase;
    }

    p {
        padding: 10px;
        margin-bottom: 10px;
        @include font-size(12px, 16px);

    }

    @include attention() {
        background-color: $color-ui-light;
        cursor: pointer;
    }

    &.checked {
        background-color: $expandend;
    }
}



.message {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    border: 1px solid transparent;
    padding: 20px;
    @include font-size(15px, 18px);
}
