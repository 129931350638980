/* ==========================================================================
   #LOGO
   ========================================================================== */

.site-logo {
    display: block;
    background: url('/trema/img/logo.svg') no-repeat;
    text-indent: -9999em;
    position: absolute;
    width: 32px;
    height: 30px;
    right: 90px;
    top: 15px;
    display: block;
    background-size: cover;

    @include mq($from: phone) {
        display: block;
        width: 156px;
        height: 65px;
        margin-left: -78px;
        background-size: auto;
        top: -1px;
        left: 50%;
        right: auto;
    }
}
