/* ==========================================================================
   #CONTENT
   ========================================================================== */

.content--small {
    max-width: 700px;
    margin: 0 auto;

}

.spacer {
    border-top: solid 2px $color-ui;
    margin-bottom: 16px;
}