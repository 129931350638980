///* ========================================================================
//   #Hambrurgfer
//   ======================================================================== */


$hamburgers-padding-x: 20px;
$hamburgers-padding-y: 15px;
$hamburger-types     : (vortex);
$hamburger-layer-width                     : 40px ;
$hamburger-layer-height                    : 4px ;
$hamburger-layer-spacing                   : 6px ;
$hamburger-layer-color                     : $color-blue ;
$hamburger-layer-border-radius             : 0px ;
$hamburger-hover-opacity                   : 0.7 ;
$hamburger-hover-transition-duration       : 0.15s ;
$hamburger-hover-transition-timing-function: linear ;