/*------------------------------------*\
    #HELPERS
\*------------------------------------*/


.hovered {
    cursor: pointer;
}

.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.text--centered {
    text-align: center;
}

.text--left {
    text-align: left;
}

.text--right {
    text-align: right;
}

.caps {
    text-transform: uppercase;
}

.is-hidden {
    display: none;
}

.float--right {
    float: right;
}

.float--left {
    float: left;
}

// RESPONSIVE IFRAMES
iframe, object, embed {
    max-width: 100%;
}


.clickable {
    display: block;
}

a.go {
   text-decoration: double;
    &:after {
        content: ' >';
    }
}



.log-out {
    text-decoration: underline!important;
    @include font-size(14px);
}


.cta {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
}


.disabled {
    display: none;
}



.disco {

    animation:myfirst 5s infinite;
}



@-webkit-keyframes myfirst /* Safari and Chrome */
{
    0%   {  background: $color-brand;}
    50%  {  background: darken(#B8E986, 5%); }
    100%   {    background: $color-brand;}
}