/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    font: inherit; /* [3] */
    text-align: center; /* [4] */
    margin: 0; /* [4] */
    cursor: pointer; /* [5] */
    padding: 6px 20px 6px 20px;
    transition: $global-transition;
    border-radius: $global-radius;
    border: 0;
    letter-spacing: 1px;
    text-transform: lowercase;
    color: $color-brand;
    @include attention() {
        color: darken($color-btn-primary, 10%);
    }
}


/* Ghost buttons
   ========================================================================== */

/**
 * Ghost buttons have see-through backgrounds and are bordered.
 */

$btn-ghost-border-width: 1px;

.c-btn--ghost {
    border: $btn-ghost-border-width solid currentColor;
    padding: ($inuit-global-spacing-unit-small - $btn-ghost-border-width) ($inuit-global-spacing-unit - $btn-ghost-border-width);

    &,
    &:hover,
    &:active,
    &:focus {
        background: none;
    }

    &.c-btn--small {
        padding: ($inuit-global-spacing-unit-tiny - $btn-ghost-border-width) ($inuit-global-spacing-unit-small - $btn-ghost-border-width);
    }

    &.c-btn--large {
        padding: ($inuit-global-spacing-unit - $btn-ghost-border-width) ($inuit-global-spacing-unit-large - $btn-ghost-border-width);
    }



    &:hover,
    &:focus {
        background-color: $color-brand;
        color: #fff;
    }



}

/* Naked, no body, just naked
   ========================================================================== */

.c-btn--naked {
    padding: 0 !important;
}


button:focus {
    outline:0;
}
