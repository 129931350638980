/*------------------------------------*\
	NAV
\*------------------------------------*/

/**

https://jonsuh.com/hamburgers/

 */

.hamburger {
    position: absolute;
    top: 2px;
    left: 80px;
}


.main-nav {
    background-color: $color-blue-light;
    position: absolute;
    top: $page-header-desktop;
    left: 0;
    max-width: 360px;
    padding: $base-spacing-unit;
    display: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @include mq($until: tablet) {
        height: calc(100vh - 60px);

        nav {
            padding-bottom: 100px;
        }
    }
}


.nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-editons {
    width: 85px;
    height: $page-header-desktop;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-left: solid 1px $color-ui;
    @include attention() {
        background-color: $color-ui-light;
    }
    svg {
        fill: $color-brand;
        width: 30px;
        height: 30px;
        margin-top: 15px;
        margin-left: calc(55px / 2);
    }

}

.menu-editions--active {
    background-color: $color-red;
    height: 61px;
    @include attention() {
        background-color: darken($color-red, 5%);
    }
    svg {
        fill: #fff;

    }
}


.menu-search {
    width: 72px;
    height: $page-header-desktop;
    position: absolute;
    top: 0;
    right: 85px;
    border-left: solid 1px $color-ui;
    display: none;
    @include mq($from: phone) {
        display: block;
    }

    @include attention() {
        background-color: $color-ui-light;
    }
    svg {
        fill: $color-brand;
        width: 30px;
        height: 30px;
        margin-top: 15px;
        margin-left: calc(42px / 2);
    }

}

.menu-search--active {
    svg {
        fill: $color-blue-light;
    }

    @include attention() {
        background-color: $color-blue-grey;
    }

}

.nav__link {
    color: #004686;
}


.nav__item {
    border-bottom: solid 1px rgba(140,140,140,0.5);
    padding: 14px 0 8px 0;
    @include font-size(15px, 20px);
}



.nav--type {
    color: $color-red;
    display: block;
    @include font-size(12px, 17px);
    text-transform: uppercase;
    margin-bottom: 4px;
}

.nav--small {
    border-bottom: none;
    text-transform: uppercase;
    margin-top: 30px;
    .nav__item {
        border-bottom: none;
        padding: 4px 0 ;
        @include font-size(13px);
    }
    .nav__link {
        color: $color-grey-darken;
    }

}

.nav__authors {
    @include font-size(11px);
    color: $color-grey-darken;
    margin-top: 4px;
}


.nav__show-mobile {
    display: none;
    @include mq($until: tablet) {
        display: block;
    }
}


/** PREVENT BODY FROM SCROLLING,
 * when menu is expanded
 */


.noscroll {
    overflow: hidden;
}


