///* ========================================================================
//   #WRAPPER
//   ======================================================================== */

.o-wrapper {

    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;
    max-width: 960px;
}

